<template>

    <div class="main-wrapper">
        <NavbarDashboard></NavbarDashboard>
        <SidebarDashboard></SidebarDashboard>
        <div class="main-content">
            <section class="section">
                <h4 class="title">Tambah Contact Vendor</h4>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                       <li class="breadcrumb-item">
                            <router-link to="/data-vendor">Vendor </router-link>
                        </li>
                        <li class="breadcrumb-item"><router-link to="/data-vendor/data-contact">Contact Vendor </router-link></li>
                        <li class="breadcrumb-item active" aria-current="page"> Tambah</li>
                    </ol>
                </nav>
                <div class="card info-detail">
                  
                        <ul class="nav nav-pills">
                            <li class="nav-item">
                                <router-link to="/data-vendor/detail" class="nav-link ">Detail Vendor
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/data-vendor/data-contact" class="nav-link active">Contact Vendor</router-link>
                            </li>
                        </ul>
                  

                    <div class="row  mt-5">
                        <div class="col-6">
                            <div class="title">Detail Contact Vendor</div>
                            <div class="sub-title">Tambah informasi contact vendor yang diperlukan</div>
                        </div>
                        <div class="col-6 align-self-center text-right">

                            <div class="groupBtn-save-edit">
                                <button class="btn btn-save">Simpan</button>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row mt-2">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="inputAddress2">Nama : </label>
                                <input type="text" class="form-control" id="inputAddress2" value="Nigel Mansell"
                                    placeholder="Masukkan Nama Vendor" >
                            </div>

                               <div class="form-group">
                                <label for="inputAddress2">Email :</label>
                                <input type="email" class="form-control" id="inputAddress2" 
                                    placeholder="Masukkan Email" value="nigel.man@gmail.com">
                            </div>

                             <div class="form-group">
                                <label for="inputAddress2">Nomor Telepon :</label>
                                <input type="text" class="form-control" id="inputAddress2" value="087412531273"
                                    placeholder="Masukkan Nomor Telepon" >
                            </div>
                            <div class="form-group">
                                <label for="inputAddress2">Nomor Handphone :</label>
                                <input type="text" class="form-control" id="inputAddress2" value="087412531273"
                                    placeholder="Masukkan Nomor Handphonen" >
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="form-group">
                                <label for="inputAddress2">Nomor Whatsapp :</label>
                                <input type="text" class="form-control" id="inputAddress2" value="087412531273" 
                                    placeholder="Masukkan Nomor Whatsapp">
                            </div>
                            <div class="form-group">
                                <label for="inputAddress2">Jabatan :</label>
                                <input type="email" class="form-control" id="inputAddress2" 
                                    placeholder="Masukkan Jabatan" value="Akuntan">
                            </div>

                            <div class="form-group">
                                <label for="inputAddress2">Status :</label>
                                <div class="form-group edit-vendor">
                                    <Select2 v-model="Satatus" :options="statusCompany" placeholder="Status"
                                        :settings="{ settingOption: value, settingOption: value }"
                                        @change="myChangeEvent($event)" @select="mySelectEvent($event)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>





<script>
    import SidebarDashboard from '../../../../components/Sidebar.vue'
    import NavbarDashboard from '../../../../components/Navbar.vue'
    import Select2 from 'vue3-select2-component';

    import "datatables.net-dt/js/dataTables.dataTables"
    import "datatables.net-dt/css/jquery.dataTables.min.css"

    import $ from 'jquery'

    // import TableLite from 'vue3-table-lite'
    export default {
        name: 'dataDepartement',
        components: {
            // FooterDashboard,
            SidebarDashboard,
            NavbarDashboard,
            Select2
            // TableLite
        },

        data() {
            return {
                myValue: 'aaaa',
                statusCompany: ['Aktif', 'Tidak Aktif'], // or [{id: key, text: value}, {id: key, text: value}]
                optionPeriode: ['1 Bulan', '2 Bulan'],


                placeholder: [{
                    text: 'kecamatan'
                }]
            }
        },

        mounted() {
            $('#tableDepartment').DataTable({
                info: false,
            });
        },
        methods: {
            myChangeEvent(val) {
                console.log(val);
            },
            mySelectEvent({
                id,
                text
            }) {
                console.log({
                    id,
                    text
                })
            },

            clickEdit() {
                $('.groupBtn-save-edit').removeClass("d-none")
                $('.groupBtn-detail').addClass("d-none")
                $('input').removeAttr('readonly')
                $('textarea').removeAttr('readonly')
                $('.edit-vendor ').removeClass("d-none")
                $('.input-none').addClass("d-none")
            },

            clickCancel() {
                $('input').attr('readonly')
                $('.groupBtn-detail').removeClass("d-none")
                $('.groupBtn-save-edit ').addClass("d-none")
                $('input').attr('readonly', true)
                $('.edit-vendor ').addClass("d-none")
                $('.input-none').removeClass("d-none")

            }
        },


    }
</script>


<style scoped src="../../../../assets/css/style.css">
</style>


<style scoped src="../../../../assets/css/custom.css">
</style>



<style scoped>
    .btn-save {
        background-color: #40DDD4;
        color: #fff;
        width: 90px;
        font-size: 16px;
        font-weight: 400;
        padding: 10px 0px;
    }

    .nav-pills .nav-item .nav-link.active[data-v-161fae0a]:after {
        border-radius: 5px !important;
    }

    .card .card-title {
        font-size: 18px;
        color: #000;
        font-weight: 500;
    }
</style>